import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
// import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
// import PerksModule from "../components/PerksModule/PerksModule"
// import Perk from "../components/PerksModule/Perk"
// import Features from "../components/Features/Features"
import RecipeCategories from "../components/RecipeCategories"

const Index = ({ data }) => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Burn your kitchen!"
          subTitle="Просто готувати смачно."
        />
        {/* <pre>{JSON.stringify(data.recipeCategories, null, 4)}</pre> */}
        {/* <BasicTextModule
          title="A super-fast theme that is easy to get started, using the power of
            GatsbyJS"
          content="Using modern CSS properties such as grid, this theme is optmised for
            speed and mobile devices. Giving users an excellent experience on
            any device. Future-proofing your product."
          link="/products"
          linkText="View Products"
        /> */}
        {/* <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule> */}
        {/* <Features
          title="Featured Products from Barcadia."
          introduction="Vivamus quam mauris, pulvinar vel mauris id, interdum semper neque. Proin malesuada libero eget tellus scelerisque, id egestas tortor egestas."
        /> */}
        <RecipeCategories
          title="За типом страви"
          introduction="Обери що приготувати сьогодні."
          categories={data.byDishType.nodes}
        />
        <RecipeCategories
          title="Основні інгредієнти"
          introduction="Шукай рецепти за інгредієнтами"
          categories={data.byIngredients.nodes}
        />
      </Layout>
    </>
  )
}

export const data = graphql`
  query {
    byDishType: allPrismicRecipeCategory(
      filter: { tags: { eq: "dishes" } }
      sort: { order: ASC, fields: data___order }
    ) {
      nodes {
        data {
          name {
            text
          }
          thumbnail {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
          }
        }
        uid
      }
    }
    byIngredients: allPrismicRecipeCategory(
      filter: { tags: { eq: "ingredients" } }
      sort: { order: ASC, fields: data___order }
    ) {
      nodes {
        data {
          name {
            text
          }
          thumbnail {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
          }
        }
        uid
      }
    }
  }
`

export default Index
