import React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Button from "../components/Button/Button"

export const Categories = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: calc(var(--gap) / 2);

  @media (min-width: 1200px) {
    gap: var(--gap);
  }

  @media (min-width: 1200px) {
    gap: var(--gap);
  }
`

const StyledCategoryItem = styled.aside`
  min-height: 200px;
  background-color: #000;
  flex: 0 0 100%;
  overflow: hidden;
  scroll-snap-align: center;
  scroll-margin-left: 25px;
  position: relative;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  transition: border-color 0.6s ease, box-shadow 0.6s ease;

  @media (min-width: 414px) {
    min-height: 250px;
    flex-basis: calc(50% - 10px);
  }

  @media (min-width: 768px) {
    min-height: 300px;
  }

  @media (min-width: 1024px) {
    min-height: 400px;
  }

  @media (min-width: 1200px) {
    flex-basis: calc(50% - 27px);
    flex-shrink: 1;
  }

  .features__item--img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .features__item--content {
    width: 100%;
    position: absolute;
    z-index: 2;
    padding: 20px 10px;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    @media (min-width: 768px) {
      padding: 20px;
    }

    @media (min-width: 1024px) {
      padding: 40px 20px;
    }

    @media (min-width: 1200px) {
      padding: 50px 30px;
    }

    h4,
    p {
      text-shadow: var(--textShadow);
    }

    h4 {
      color: #fff;
    }

    p {
      color: var(--bodyColor);
    }
  }

  &:hover {
    cursor: pointer;
    border-color: var(--primary);
    box-shadow: var(--boxShadow);

    .features__item--img {
      transform: scale(1.1);
      opacity: 0.5;
    }
  }
`

const CategoryItem = ({ node }, key) => {
  const { data, uid } = node
  const image = getImage(data.thumbnail)

  return (
    <StyledCategoryItem key={key}>
      <Link to={`/recipes/${uid}/`}>
        {/* <pre>{JSON.stringify(node, null, 4)}</pre> */}
        <GatsbyImage
          className="features__item--img"
          image={image}
          alt="Product Image"
        />
        <div className="features__item--content">
            {data.name && <h4>{data.name.text}</h4>}
            {/* {introduction && <p>{introduction}</p>} */}
        </div>
      </Link>
    </StyledCategoryItem>
  )
}

const RecipeCategories = ({ title, introduction, categories }) => {
  return (
    <div className="section">
      <div className="container container__tight">
        {title || introduction ? (
          <div className="intro__area">
            {title && (
              <h2>
                {title}
                <span>.</span>
              </h2>
            )}
            {introduction && <p>{introduction}</p>}
          </div>
        ) : null}

        <Categories>
          {categories.map((node, index) => {
            return <CategoryItem key={index} node={node} />
          })}
        </Categories>
      </div>
    </div>
  )
}

export default RecipeCategories
